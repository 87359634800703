import { useEffect } from 'react';
import { AppCacheProvider } from '@mui/material-nextjs/v13-pagesRouter';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-license-pro';
import Head from 'next/head';

import { getRequiredEnvVar } from '@cherry/utils';

import '../styles/global.css';

import useGoogleAnalytics from '@/hooks/useGoogleAnalytics';

import theme from '../theme';

const MUI_LICENSE = getRequiredEnvVar(process.env.MUI_LICENSE, 'MUI_LICENSE');
LicenseInfo.setLicenseKey(MUI_LICENSE);

export default function MyApp({ Component, pageProps, ...props }) {
  const analytics = useGoogleAnalytics();

  useEffect(() => {
    analytics?.pageView();
  }, [analytics]);

  return (
    <AppCacheProvider {...props}>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <meta name="theme-color" content={theme.palette.primary.main} />
      </Head>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Component {...pageProps} />
      </ThemeProvider>
    </AppCacheProvider>
  );
}
