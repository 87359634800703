;globalThis["__sentryRewritesTunnelPath__"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"f993b8f26f39048f1804f69142cbbcb31a05603f"};globalThis["__sentryBasePath"] = undefined;globalThis["__rewriteFramesAssetPrefixPath__"] = "";import * as Sentry from '@sentry/nextjs';

import { getRequiredEnvVar } from '@cherry/utils';

const environment = getRequiredEnvVar(process.env.ENVIRONMENT, 'ENVIRONMENT');

const userPoolWebClientId = getRequiredEnvVar(
  process.env.COGNITO_USER_POOL_WEB_CLIENT_ID,
  'COGNITO_USER_POOL_WEB_CLIENT_ID'
);
const LastAuthUser_COOKIE_KEY = `CognitoIdentityServiceProvider.${userPoolWebClientId}.LastAuthUser`;

const debug = getRequiredEnvVar(process.env.SENTRY_DEBUG, 'SENTRY_DEBUG') === 'true';
const tracesSampleRate = Number(getRequiredEnvVar(process.env.SENTRY_TRACE_SAMPLE_RATE, 'SENTRY_TRACE_SAMPLE_RATE'));
const dsn = getRequiredEnvVar(process.env.SENTRY_DSN, 'SENTRY_DSN');

const getAllCookies = () => {
  const cookieArray = document.cookie.split(';');

  if (cookieArray[0] === '') return null;

  const cookies = {};
  for (const cookie of cookieArray) {
    const [name, value] = cookie.trim().split('=');
    cookies[name] = value;
  }

  return cookies;
};

Sentry.init({
  dsn,

  environment,

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug,

  replaysOnErrorSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.captureConsoleIntegration(['error']),
    Sentry.extraErrorDataIntegration(),
  ],
  initialScope: async (scope) => {
    scope.setContext('next-js', { version: window.next?.version });
    scope.setContext('culture', {
      type: 'culture',
      locale: window.navigator.language,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });

    const allCookies = getAllCookies();
    if (allCookies !== null) {
      const id = allCookies[LastAuthUser_COOKIE_KEY];
      scope.setUser({
        id,
        username: allCookies.username,
      });
    }
  },
});
