/** @typedef {import('react-ga').ReactGA} ReactGA */

/** @type Promise<ReactGA> */
let ReactGAPromise;

/**
 * Initializes and returns an object with methods for Google Analytics tracking.
 *
 * @param {Object} [options] - Configuration options for Google Analytics.
 * @param {boolean} [options.enableDebug=false] - Flag to enable debug mode.
 * @returns {ReactGA} An object with methods for event tracking, sending data, and page view tracking.
 * @returns {Function} return.event - Method to track events.
 * @returns {Function} return.send - Method to send data.
 * @returns {Function} return.pageView - Method to track page views.
 */
export default function googleAnalytics({ enableDebug } = { enableDebug: false }) {
  const trackingId = process.env.GA_TRACKING_ID;

  if (trackingId) {
    if (!ReactGAPromise) {
      ReactGAPromise = initializeReactGA({ trackingId, enableDebug });
    }
    return {
      event: (...args) =>
        ReactGAPromise.then(({ ReactGA }) => {
          return ReactGA.event(...args);
        }),
      send: (...args) =>
        ReactGAPromise.then(({ ReactGA }) => {
          return ReactGA.send(...args);
        }),
      pageView: () =>
        ReactGAPromise.then(({ ReactGA }) => {
          return ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: document.title });
        }),
    };
  }

  return {
    event: (args) => {
      enableDebug && console.log('[googleAnalytics.event]', { args });
    },
    send: (args) => {
      enableDebug && console.log('[googleAnalytics.send]', { args });
    },
    pageView: () => {
      enableDebug &&
        console.log('[googleAnalytics.pageView]', {
          hitType: 'pageview',
          page: window.location.pathname,
          title: document.title,
        });
    },
  };
}

async function initializeReactGA({ trackingId, enableDebug = false }) {
  const [{ email_domain, sub }, rga4Module] = await Promise.all([getUserInfo(), import('react-ga4')]);
  const ReactGA = new rga4Module.ReactGAImplementation();

  // https://github.com/codler/react-ga4/blob/master/src/ga4.js#L11
  const reactGAOptions = {
    gtagOptions: {
      allowAdFeatures: false,
      allowAdPersonalizationSignals: false,
      userId: sub, // https://developers.google.com/analytics/devguides/collection/ga4/user-id?client_type=gtag#send_user_ids
      debug_mode: enableDebug,
    },
  };

  ReactGA.gtag('set', 'user_properties', { email_domain });
  ReactGA.initialize(trackingId, reactGAOptions);

  return { ReactGA, email_domain, sub };
}

async function getUserInfo() {
  try {
    const [{ Amplify, Auth }, awsConfig] = await Promise.all([import('aws-amplify'), import('@/aws-exports')]);

    Amplify.configure(awsConfig);

    const user = await Auth.currentAuthenticatedUser();
    const email_domain = user.attributes.email.split('@')[1];
    return { email_domain, sub: user.attributes.sub };
  } catch (error) {
    console.warn('[googleAnalytics.getUserInfo] Amplify auth failed. Falling back to cookies...', error);
    return { email_domain: getCookie('email_domain'), sub: getCookie('sub') };
  }
}

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').map(decodeURIComponent).shift();
}
