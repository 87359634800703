import { useEffect, useState } from 'react';
import googleAnalytics from '@/lib/client/googleAnalytics';

let analyticsInstance = null;

const useGoogleAnalytics = (enableDebug = false) => {
  const [analytics, setAnalytics] = useState(analyticsInstance);

  useEffect(() => {
    if (analyticsInstance === null) {
      analyticsInstance = googleAnalytics({ enableDebug });
    }
    setAnalytics(analyticsInstance);
  }, [enableDebug]);

  return analytics;
};

export default useGoogleAnalytics;
